import React, { Component } from 'react'
import { Link } from 'gatsby'
import IconLogo from '../renderedImages/IconLogoWhite'
import Logo from '../renderedImages/LogoWhite'
import data from '../../data/content.json'

export default class footer extends Component {
    state = {
        flyout: []
    }
    
    componentDidMount()  {
        if (data) {
          this.setState({
            flyout: data.flyout,
          });
        }
    }

    render() {
        const today = new Date();
        const year = today.getFullYear();
        const { flyout } = this.state;
    
        if (flyout === null) return <div className="home-loading">loading...</div> 
        return (
            <>
                <footer>
                    <section className="footer">
                        <div className="footer-top">
                            <div className="footer-logos">
                                <div className="logo-icon">
                                    <IconLogo />
                                </div>
                                <div className="logo">
                                    <Logo additionalClass="footer__logo" />
                                    <p>Promotional Products & Custom Printing</p>
                                    <div className="contact-details">
                                        <p>9018 Balboa Blvd. #355 <br/>Northridge, CA 91325-2610</p>
                                        <p>Tel.&nbsp;<a href="tel:1-818-894-3050">1.818.894.3050</a> / <Link to="/contact">Contact</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-nav-container">
                                <h2>Navigation</h2>
                                <nav className="footer-nav">
                                    <ul className="info-nav">
                                        <li><Link to="/about">About</Link></li> 
                                        <li><Link to="/contact">Contact</Link></li>
                                        <li><Link to="/products">Products</Link></li>
                                        <li><Link to="/privacy">Privacy</Link></li>
                                    </ul>
                                    <ul className="products-nav">
                                        {flyout.map ( (flyoutItem, idx) => {
                                            return (
                                                <li key={idx} className="">
                                                    <Link to={`/${flyoutItem.path}`} >
                                                        {flyoutItem.label}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="footer-bottom">
                            <div className="copyright">
                                { year } Tiger Press &copy; Developer&mdash;<a href="https://jychung.com">Jychung</a>
                            </div>
                        </div>
                    </section>
                    
                </footer>
                {/* <div className="developer">
                    <a href="https://jychung.com">Jychung Websites</a>&mdash;Website Designer and Developer
                </div> */}
            </>
        )
    }
}