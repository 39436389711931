import React from 'react';

function Compose() {
  return (
    <svg height="16px" viewBox="0 0 20 20">
      <path d="M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"/>
    </svg>
  );
}

export default Compose;