import React, { Component } from 'react'
import { Link } from "gatsby"

import IconLogo from '../renderedImages/IconLogo'
import Logo from '../renderedImages/Logo'
import data from '../../data/content.json'
import Compose from '../icons/compose'
import Toggle from '../parts/Toggle'
import Up from '../icons/up'
import Down from '../icons/down'
import CloseIcon from '../icons/CloseIcon'

export default class header extends Component {
    state = {
        flyout: []
    }

    constructor() {
        super();
        this.state = {
            showProductsMenu: false,
        };
        this.showProductsMenu = this.showProductsMenu.bind(this);
        this.closeProductsMenu = this.closeProductsMenu.bind(this);
    }
      
    showProductsMenu(event) {
        event.preventDefault();
        this.setState({ showProductsMenu: true }, () => {
            document.addEventListener('click', this.closeProductsMenu);
        });
    }

    closeProductsMenu(event) {
        if ( this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ showProductsMenu: false }, () => {
                document.removeEventListener('click', this.closeProductsMenu);
            });
        }
    }

    componentDidMount()  {
        if (data) {
            this.setState({
                flyout: data.flyout,
            });
        }
    }

    addClass = () => {
        document.body.classList.add('js-noscroll')
      };
      removeClass = () => {
          document.body.classList.add('js-noscroll')
        };

    render() {
        const { flyout, showProductsMenu } = this.state;
        if (flyout === null) return <div className="home-loading">loading...</div> 
        return (
            <>
                <header className="header">
                    <div className="header-home-link-container">
                        <Link to="/" className="header-icon-logo-container">
                            <div className="header-logos"><IconLogo /></div>
                        </Link>
                        <Link to="/" className="header-logo-container">
                            <div className="header-logos"><Logo /></div>
                        </Link>
                    </div>
                    <nav>
                        <ul className="header-nav">
                            <li><Link to="/products">Products</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </header>

                <div className="flyout-header">
                    <div className="flyout">
                        <div className="flyout-item">
                            <Link to="/contact" className="flyout-link">
                                {Compose()} Request Custom Product
                            </Link>
                        </div>
                        {flyout && flyout.map ( (flyoutItem, idx) => {
                            return (
                                <div key={idx} className="flyout-item">
                                    <Link to={`/${flyoutItem.path}`} activeClassName="active" className="flyout-link">
                                        {flyoutItem.label}
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <header className="mobile-header">
                    <Toggle>
                        {({ on, toggle}) => (
                            <div className="nav">
                                { !on ? this.removeClass : this.addClass }
                                { on ? <div className="js-overlay js-noscroll" onClick={toggle} role="layout"></div> : null }
                                <div className="nav__mobile-toggle">
                                    <div className={`open-menu ${ !on ? " " : " close-menu" }`} onClick={toggle} role="button" aria-haspopup="true" aria-expanded={on ? true : false}>
                                        <div className="top-line"></div>
                                        <div className="inside-line"></div>
                                        <div className="bottom-line"></div>
                                    </div>
                                </div>
                                <div className="mobile-header-logos">
                                    <Link to="/" className="header-icon-logo-container">
                                        <div className="header-logos"><IconLogo /></div>
                                    </Link>
                                </div>
                                <nav className={`nav__container ${ on ? `` : `nav__mobile-nav-closed` }`}>
                                    <ul className="nav__list-wrap" role="navigation">
                                        <li><Link onClick={toggle} role="link" className="nav__main-nav" to="/">Home</Link></li>
                                        <li><Link onClick={toggle} role="link" className="nav__main-nav" to="/about">About</Link></li>
                                        <li>
                                            <button onClick={this.showProductsMenu} className="nav__main-nav" aria-haspopup="true" aria-expanded={showProductsMenu ? true : false}>Products{ showProductsMenu ? Up() : Down() }</button>
                                            <ul onClick={toggle} role="button" className={`nav__dropdown ${showProductsMenu ? ` nav__dropsdown` : ``}`} role="menu" ref={element => this.dropdownMenu = element}>
                                                <li className="">
                                                    <Link to="/products" activeClassName="active" className="flyout-link">
                                                        All Products
                                                    </Link>
                                                </li>
                                                {flyout && flyout.map ( (flyoutItem, idx) => {
                                                    return (
                                                        <li key={idx} className="">
                                                            <Link to={`/${flyoutItem.path}`} >
                                                                {flyoutItem.label}
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                        <li><Link onClick={toggle} className="nav__main-nav" to="/contact">Contact</Link></li>
                                        <li><div onClick={toggle} className="nav__main-nav">{CloseIcon()}Close</div></li>
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </Toggle>

                </header>
            </>
        )
    }
}
