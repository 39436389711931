import React, { Component } from 'react'
import Phone from '../icons/phone'
import Send from '../icons/send'
import Envelope from '../icons/envelope'

export default class ContactInformation extends Component {
    render() {
        return (
            <div className="contact-info-container">
                <h1 className="page-title">Contact Information</h1>
                <div className="contact-info">
                    <div className="contact">
                        {Phone()} <a href="tel:1-818-894-3050">1.818.894.3050</a>
                    </div>
                    <div className="contact">
                        {Send()} <a href="mailto:tigerpress_22@verizon.net">tigerpress_22@verizon.net</a>
                    </div>
                    <div className="contact">
                        {Envelope()} 9018 Balboa Blvd. #355 <br/>Northridge, CA 91325-2610
                    </div>
                </div>
            </div>
        )
    }
}
