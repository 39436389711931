import React from 'react';

function Send() {
  return (
    <svg height="20px" viewBox="0 0 20 20">
      <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z"/>
    </svg>
  );
}

export default Send;